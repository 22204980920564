import { ActionIcon, Badge, Box, Flex, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Product } from 'types';
import { ActivityTicket } from 'interfaces';
import {
  BlockTrialTypeEnum,
  PebbleButtonsEnum,
  PricingPolicyEnum,
  SubscriptionTrialTypeEnum,
} from 'enums';
import { trackAction, Actions } from 'utils/amplitude';
import { useMediaQuery } from '@mantine/hooks';
import { useBasket } from 'context/BasketContext';
import { PriceInfo } from './PriceInfo';
import classes from './TicketTypesCard.module.scss';
import { Plus, Minus, HandHeart, Coins, ReceiptX } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/ui';
import { useMemo } from 'react';

interface ITicketTypesCard {
  ticket: ActivityTicket;
  selectedSession: Product | null;
  addTicket(ticketId: string): void;
  removeTicket(ticketId: string): void;
  ticketCount: number;
  noSpacesLeft: boolean;
  isSubscription: boolean;
  loading: boolean;
  handleSubscriptionBookNow: (subsTicket?: ActivityTicket) => Promise<void>;
  blockTrialType: BlockTrialTypeEnum;
  blockTrialSessionCount: number;
  blockTrialPrice: number;
  handleTrialOnBlockBookNow: (subsTicket?: ActivityTicket) => Promise<void>;
  setShowErrorMessage: (val: boolean) => void;
  subsTrialType?: SubscriptionTrialTypeEnum;
}

const TicketTypesCard: React.FC<ITicketTypesCard> = ({
  ticket,
  selectedSession,
  noSpacesLeft,
  isSubscription,
  loading,
  handleSubscriptionBookNow,
  ticketCount,
  addTicket,
  removeTicket,
  blockTrialType,
  blockTrialSessionCount,
  blockTrialPrice,
  handleTrialOnBlockBookNow,
  setShowErrorMessage,
  subsTrialType,
}) => {
  const { basketItems, trialsOnBlocksSelected } = useBasket();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const theme = useMantineTheme();

  const attendeeCount = ticketCount * ticket.capacity;

  const isFreeTicket = ticket.pricingPolicy === PricingPolicyEnum.FREE;
  const isPerSessionTicket = ticket.pricingPolicy === PricingPolicyEnum.PER_SESSION;
  const isFullBlockTicket = ticket.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE;

  const totalTicketValue =
    basketItems && basketItems.length > 0 && selectedSession?.singleSessionDate
      ? ticket.price * ticketCount * basketItems.length
      : ticket.price * ticketCount;

  const trialBadgeLabel = useMemo(() => {
    if (subsTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return 'Free trial available';
    }
    if (subsTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return 'Paid trial available';
    }
    return '';
  }, [subsTrialType]);

  const trialBadgeIcon = useMemo(() => {
    if (subsTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return <HandHeart size={16} />;
    }
    if (subsTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return <Coins size={16} />;
    }
    return null;
  }, [subsTrialType]);

  const getSessionFormat = (sessionCount: number) => {
    return sessionCount > 1 ? 'sessions' : 'session';
  };

  const getTicketPrice = () => {
    if (!selectedSession) return;

    if (isPerSessionTicket) {
      return `${formatPenceToPounds(
        totalTicketValue * selectedSession?.numberOfSessionsAvailable,
      )}`;
    }

    return `${formatPenceToPounds(totalTicketValue)}`;
  };

  const getPricePerSessionLabelSubscription = (subPriceButton = false) => {
    if (!selectedSession) return;

    const ticketPrice = formatPenceToPounds(ticket.price);

    return subPriceButton ? `${ticketPrice} / mth` : `${ticketPrice} / MONTH`;
  };

  const getPricePerSessionLabel = () => {
    if (!selectedSession) return;

    if (trialsOnBlocksSelected) {
      const numberOfAllSessions = selectedSession?.numberOfSessionsAvailable;
      const numberOfPostTrialSessions = numberOfAllSessions - blockTrialSessionCount;
      return (
        <Stack gap={0}>
          <Text fw={600} size="sm">
            {blockTrialSessionCount}
            {' x '}
            {blockTrialType === BlockTrialTypeEnum.FREE_TRIAL ? 'Free' : 'Paid'}
            {' trial session'}
            {blockTrialSessionCount > 1 ? 's' : ''}
          </Text>
          {isFullBlockTicket && numberOfPostTrialSessions > 0 ? (
            <Text fw={600} size="sm">
              {formatPenceToPounds(ticket.price)} (set price for {numberOfPostTrialSessions} session
              {numberOfPostTrialSessions > 1 ? 's' : ''} after trial)
            </Text>
          ) : numberOfPostTrialSessions > 0 ? (
            <Text fw={600} size="sm">
              {formatPenceToPounds(ticket.price * numberOfPostTrialSessions)}
              {' ('}
              {numberOfPostTrialSessions}
              {` paid session${numberOfPostTrialSessions > 1 ? 's' : ''} after trial)`}
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      );
    }
    if (isFullBlockTicket) {
      return (
        <>
          <Text fw={600} pr={4}>
            {formatPenceToPounds(ticket.price)}{' '}
          </Text>{' '}
          / Set Price
        </>
      );
    }

    if (selectedSession?.singleSessionDate && basketItems && basketItems.length > 0) {
      const totalBasketPrice = !isFreeTicket
        ? formatPenceToPounds(ticket.price * basketItems.length)
        : '';

      return (
        <Flex data-testid={'basket-price-session'} align={'center'}>
          <Text fw={600} pr={4}>
            {totalBasketPrice}
          </Text>{' '}
          ({basketItems.length} {getSessionFormat(basketItems.length)})
        </Flex>
      );
    }

    const numberOfSessions = selectedSession?.numberOfSessionsAvailable;

    const totalSessionsPrice = !isFreeTicket
      ? formatPenceToPounds(ticket.price * numberOfSessions)
      : '';

    return (
      <Flex data-testid={'basket-price-block'} align="center">
        <Text fw={600} pr={4}>
          {totalSessionsPrice}
        </Text>
        ({numberOfSessions} {getSessionFormat(numberOfSessions)})
      </Flex>
    );
  };

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions;

  return (
    <Box
      className={classNames(classes.card, {
        [classes.unavailable]: !ticket.isAvailable,
        [classes.subscriptionCard]: isSubscription,
      })}
      py={isMobile ? 24 : 16}
    >
      <div className={classes.ticketInfoWrapper}>
        <Text component="h2" className={classes.ticketName}>
          {ticket.name}
        </Text>
        <Flex
          className={classNames(classes.ticketPrice, { [classes.ticketPriceSubs]: isSubscription })}
          py="xs"
          data-testid="ticket-price"
        >
          {ticket.price === 0 && (
            <Text component="p" className={classes.freeTicket}>
              Free
            </Text>
          )}
          {isSubscription ? getPricePerSessionLabelSubscription(false) : getPricePerSessionLabel()}
        </Flex>
        <Text className={classes.ticketDescription}>{ticket.description}</Text>
      </div>

      {!ticket.isAvailable && (
        <Group className={classes.inputWrapper}>
          <Text>UNAVAILABLE</Text>
        </Group>
      )}

      {ticket.isAvailable && isSubscription && (
        <Group className={classes.inputWrapper}>
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.PRIMARY}
            onClick={() => {
              handleSubscriptionBookNow(ticket);
              trackAction(Actions.SUB_SUBSCRIBE);
            }}
            loading={loading}
            mt="sm"
            px="xl"
            mb={isMobile ? 'sm' : 0}
            size="md"
            radius={60}
            fullWidth
          >
            Subscribe{' '}
            {selectedSession && (
              <span className={classes.bookButtonPrice}>
                {getPricePerSessionLabelSubscription(true)}
              </span>
            )}
          </PebbleButtonSet>

          <Stack className={classes.cancelWrapper} gap={8}>
            <PriceInfo price={ticket.price} prorataPrice={ticket.prorataPrice} />
            <Group gap="sm">
              {subsTrialType !== SubscriptionTrialTypeEnum.NO_TRIAL && (
                <Badge
                  title={trialBadgeLabel}
                  classNames={{
                    root: classNames(classes.rootBadge, {
                      [classes.trialBadge]: subsTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL,
                      [classes.paidTrialBadge]:
                        subsTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL,
                    }),
                    label: classes.innerBadge,
                  }}
                  leftSection={trialBadgeIcon}
                >
                  {trialBadgeLabel}
                </Badge>
              )}
              <Badge
                title="Cancel anytime"
                classNames={{
                  root: classNames(classes.rootBadge, {
                    [classes.trialBadge]: subsTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL,
                    [classes.paidTrialBadge]:
                      subsTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL,
                  }),
                  label: classes.innerBadge,
                }}
                leftSection={<ReceiptX size={16} weight="light" />}
              >
                Cancel anytime
              </Badge>
            </Group>
            <Text className={classes.cancelCopy}>Subject to provider T&Cs</Text>
          </Stack>
        </Group>
      )}

      {ticket.isAvailable && !isSubscription && !trialsOnBlocksSelected && (
        <Flex
          direction={isMobile ? 'row' : 'column'}
          gap={isMobile ? 12 : 0}
          className={classes.inputWrapper}
        >
          <div className={classes.buttonWrapper}>
            <ActionIcon
              title="minus-button"
              disabled={ticketCount <= 0}
              size={36}
              variant="default"
              onClick={() => {
                removeTicket(ticket.id);
                setShowErrorMessage(false);
                trackAction(Actions.MINUS_BASKET);
              }}
              className={classes.minusButton}
              mr={isMobile ? 4 : 8}
            >
              <Minus
                size={18}
                color={ticketCount > 1 ? `${theme.colors.pink[5]}` : '#4c5471'}
                weight="bold"
              />
            </ActionIcon>
            <ActionIcon
              title="add-button"
              disabled={noSpacesLeft}
              size={36}
              variant="default"
              className={classes.addButton}
              onClick={() => {
                addTicket(ticket.id);
                setShowErrorMessage(false);
                trackAction(Actions.ADDITION_BASKET);
              }}
            >
              <Plus size={18} weight="bold" />
            </ActionIcon>
          </div>

          <Group className={classes.attendeesLabel}>
            <Text fw="bold" pl={'xs'}>
              {attendeeCount}
            </Text>
            <Text className={classes.growLabel}>
              {attendeeCount > 1 ? 'Attendees' : 'Attendee'}
            </Text>
            {ticket.price > 0 ? (
              <Text pr={'xs'} fw="600">
                {getTicketPrice()}
              </Text>
            ) : (
              <Text className={classes.freeTicket} fw={700} fs={'lg'} pr={'xs'}>
                Free
              </Text>
            )}
          </Group>
        </Flex>
      )}
      {trialsOnBlocksSelected && ticket.isAvailable && (
        <PebbleButtonSet
          btnVariant={PebbleButtonsEnum.PRIMARY}
          size="md"
          onClick={() => {
            handleTrialOnBlockBookNow(ticket);
          }}
          loading={loading}
          mt="sm"
          px="xl"
          fullWidth={isMobile}
        >
          Book trial
          {blockTrialType === BlockTrialTypeEnum.PAID_TRIAL && (
            <span>
              : {Number((blockTrialPrice * ticket.capacity) / 100).toLocaleString('en-GB', opts)}
            </span>
          )}
        </PebbleButtonSet>
      )}
    </Box>
  );
};

export default TicketTypesCard;
